import Layout from '@/components/Layout/index.vue';

export const menuRoutes = [
  // 所有角色都有的路由
  {
    path: '/home',
    name: '首页',
    meta: { permissions: false, icon: 'home' },
    component: () => import('../views/Home/index.vue')
  },
  {
    path: '/task',
    name: '任务管理',
    meta: { permissions: false, icon: 'shopping' },
    component: () => import('../views/Task/ParentLayout.vue'), // 添加 layout 组件
    children: [
      {
        path: 'create',
        name: '创建任务',
        meta: { permissions: false, icon: 'tag' },
        component: () => import('../views/CreateTask/index.vue')
      },
      {
        path: 'list',
        name: '任务列表',
        meta: { permissions: false, icon: 'tag' },
        component: () => import('../views/TaskList/index.vue')
      }
    ]
  },
  {
    path: '/userProject',
    name: '项目价格',
    meta: { permissions: false, icon: 'calculator' },
    component: () => import('../views/UserProject/index.vue')
  },
  {
    path: '/taskDetail',
    name: '任务详情',
    meta: { permissions: [], icon: 'team' },
    component: () => import('../views/TaskDetail/index.vue')
  }
];

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: menuRoutes
  },
  {
    path: '/login',
    component: () => import('../views/Login/index.vue')
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '../views/NotFoundPage/index.vue')
  },
  {
    path: '/errorPage',
    component: () => import(/* webpackChunkName: "errorPage" */ '../views/ErrorPage/index.vue')
  },
  { path: '*', redirect: '/home' }
];
export default routes;
