import request from '../utils/request';

export default {
  login(data) {
    return request.post('/api/user/login', data);
  },
  logout() {
    return request.get('/api/user/logout');
  },
  addUser(data) {
    return request.post('/api/user/addUser', data);
  },
  getUserInfo() {
    return request.post('/api/user/userInfo');
  },
  getUserList(params) {
    return request.get('/user/getUserList', params);
  },
  updateState(data) {
    return request.post('/user/updateState', data);
  },
  // 支付宝单号充值
  chargeAmount(data) {
    return request.post('/user/chargeAmount', data);
  },
  charge(data) {
    return request.post('/user/charge', data);
  },
  getAgentList(params) {
    return request.get('/user/getAgentList', params);
  },
  getCustomerList(params) {
    return request.get('/pandora/getCustomerList', params);
  },
  addCustomer(data) {
    return request.post('/pandora/addCustomer', data);
  },
  deleteCustomer(id) {
    return request.post(`/pandora/delete?id=${id}`);
  },
  getBalanceRecords(params) {
    return request.get('/user/getBalanceRecords', params);
  }
};
