/**
 * 设置 localStorage
 * @param {string} key
 * @param {any} value
 */
export function setStorage(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value ?? null));
}

/**
 * 获取 localStorage
 * @param {string} key
 * @returns {any}
 */
export function getStorage(key) {
  return JSON.parse(window.localStorage.getItem(key) ?? 'null');
}

/**
 * 清除指定 localStorage
 * @param {string} key
 */
export function removeStorage(key) {
  return window.localStorage.removeItem(key);
}

/**
 * 清除所有 localStorage
 */
export function removeAllStorage() {
  return window.localStorage.clear();
}
