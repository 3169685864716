<template>
  <a-dropdown class="nav-user-wrapper">
    <div>
      <a-avatar icon="user" />
      <span class="user-name">{{ userInfo.username }}</span>
      <a-icon type="caret-down" style="margin-left: 5px" />
    </div>

    <a-menu slot="overlay">
      <a-menu-item>
        <span @click.self="logout">退出登录 </span>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapState } from 'vuex';
import userService from '@/services/user';
import { removeAllStorage } from '../../../utils/storage';
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    async logout() {
      await userService.logout();
      removeAllStorage();
      this.$router.replace('/login');
    }
  }
};
</script>
<style lang="less" scoped>
.nav-user-wrapper {
  display: inline-block;
  padding: 0 15px;
}

.user-name {
  margin: 0 8px;
  font-weight: bold;
  font-size: 1rem;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
</style>
