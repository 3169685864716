import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import { getStorage } from '@/utils/storage';

Vue.use(VueRouter);

const index = new VueRouter({
  routes,
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});
index.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
  } else {
    const token = getStorage('TOKEN');
    if (token === null || token === '') {
      next('/login');
    } else {
      next();
    }
  }
});
export default index;
