import axios from 'axios';
import router from '../router';
import { message as Message } from 'ant-design-vue';
import { getStorage, removeAllStorage } from './storage';

// 允许跨域携带 cookie
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASEURL,
  withCredentials: true
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getStorage('TOKEN');
    if (token) {
      config.headers['token'] = token;  // 设置 token 到请求头
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 添加响应拦截器
axiosInstance.interceptors.response.use(
  function (response) {
    const { code, data, message } = response.data ?? {};
    if (code === 404) {
      Message.error(message);
      return Promise.resolve(data);
    }
    if (code !== 0) {
      Message.error(message);
      return Promise.reject(message);
    }
    return Promise.resolve(data);
  },
  function (error) {
    if (error?.response?.status === 401) {
      // 如果响应状态码为401，跳转到登录页面
      removeAllStorage();
      router.push('/login');
    } else {
      Message.error('网络错误');
    }
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default {
  get: (url, data, config) =>
    axiosInstance.get(url, {
      params: data,
      ...config
    }),
  post: (url, data, config) => axiosInstance.post(url, data, config),
  put: (url, data, config) => axiosInstance.put(url, data, config),
  delete: (url, data, config, body) =>
    axiosInstance.delete(url, {
      params: data,
      data: body,
      ...config
    })
};
