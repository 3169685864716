import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ),
    userInfo: {}
  },
  getters: {
    isAdmin: (state) => state.userInfo.userRole === 2,
    isAgent: (state) => state.userInfo.userRole === 1
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    }
  }
});

export default store;
