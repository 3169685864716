<template>
  <a-layout id="components-layout-demo-responsive">
    <a-layout-sider
      breakpoint="lg"
      collapsed-width="0"
      width="150"
      :trigger="null"
      :style="siderStyle"
      v-model="collapsed"
      collapsible
      @collapse="onCollapse"
    >
      <Logo />
      <a-menu theme="dark" mode="inline" :selected-keys="[selectedKey]">
        <template v-for="route of menuRoutes">
          <template v-if="hasPermission(route)">
            <a-sub-menu v-if="route.children" :key="route.path">
              <span slot="title">
                <a-icon :type="route.meta.icon" /><span>{{ route.name }}</span></span
              >
              <template v-for="childRoute of route.children">
                <a-menu-item v-if="hasPermission(childRoute)" :key="childRoute.path">
                  <router-link :to="`${route.path}/${childRoute.path}`">
                    {{ childRoute.name }}
                  </router-link>
                </a-menu-item>
              </template>
            </a-sub-menu>

            <a-menu-item v-else :key="route.path">
              <router-link :to="route.path">
                <a-icon :type="route.meta.icon" />
                {{ route.name }}
              </router-link>
            </a-menu-item>
          </template>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout :style="contentStyle">
      <a-layout-header>
        <div class="flex-sub">
          <a-icon
            class="collapsed-icon"
            @click="toggleCollapsed"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          />
          <BreadCrumb v-show="showBreadCrumb"></BreadCrumb>
        </div>
        <NavUser />
      </a-layout-header>

      <a-layout-content :style="{ padding: '24px' }">
        <div :style="{ height: '100%' }">
          <router-view />
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { mapState } from 'vuex';
import { menuRoutes } from '@/router/routes';
import Logo from './components/Logo.vue';
import NavUser from './components/NavUser.vue';
import BreadCrumb from './components/BreadCrumb.vue';

const siderStyle = { overflow: 'auto', height: '100vh', position: 'fixed', left: 0 };
const contentStyle = { marginLeft: '150px' };
export default {
  components: { NavUser, Logo, BreadCrumb },
  computed: {
    ...mapState(['userInfo', 'isMobile']),
    showBreadCrumb() {
      return this.collapsed || !this.isMobile;
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(newPath) {
        this.selectedKey = newPath;
        if (this.isMobile) {
          this.onCollapse(!this.collapsed);
        }
      }
    }
  },
  data() {
    return {
      menuRoutes,
      siderStyle,
      contentStyle,
      selectedKey: '/home',
      collapsed: false,
      theme: 'light'
    };
  },
  created() {
    console.log(this.isMobile);
  },
  methods: {
    hasPermission(route) {
      const requiredPermissions = route.meta.permissions;
      return !requiredPermissions || requiredPermissions.includes(this.userInfo.userRole);
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    onCollapse(collapsed) {
      if (collapsed) {
        this.siderStyle = {};
        this.contentStyle = {};
      } else {
        this.siderStyle = siderStyle;
        this.contentStyle = contentStyle;
      }
      this.collapsed = collapsed;
    }
  }
};
</script>

<style scoped lang="less">
#components-layout-demo-responsive {
  min-height: 100vh;

  .nav-menu {
    color: #fff;
  }

  .ant-layout {
    background: #f0f2f5;
  }

  .collapsed-icon {
    padding: 0 18px;
    font-size: 20px;
  }

  .ant-layout-header {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    background: #fff;
    box-shadow: 0 2px 8px #f0f1f2;
  }

  .page-container {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 24px;
  }

  .flex-sub {
    flex: 1;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 100%;
  }
}
</style>
